import { defineStore } from 'pinia'

import { useMainStore } from '~/stores/main'
import useFrontDoorApi from '~/composables/useFrontDoorApi'

import CourseArea = models.server.api.sitecore.courseArea.CourseArea

type CourseAreaData = CourseArea[] | { courseAreas: CourseArea[] } | null
interface Flags {
  setAll: boolean
}

interface CourseAreaMap {
  items: Record<string, string>
  override: Record<string, string>
}

export const useCourseAreaStore = defineStore('courseArea', () => {
  const app = useNuxtApp()

  const flags = ref<Flags>({ setAll: false })
  const items = ref<CourseArea[]>([])
  const courseAreasMap = ref<CourseAreaMap>({
    items: {},
    override: {}
  })

  const getAll = computed((): CourseArea[] => items.value)
  const getOne = computed(() => (courseAreaId?: string): CourseArea => {
    const index = items.value.findIndex(
      ({ id }) => id?.toUpperCase() === courseAreaId?.toUpperCase()
    )
    return items.value[index]
  })
  const getBySlug = computed(() => (slug?: string): CourseArea | undefined => {
    return items.value.find(
      (i) => i.slug?.toUpperCase() === slug?.toUpperCase()
    )
  })
  const getByIds = computed(
    () =>
      (courseAreaIds?: Array<string | undefined>): CourseArea[] =>
        items.value.filter((ca) => ca.id && courseAreaIds?.includes(ca.id))
  )
  const courseAreaExists = computed(
    () => (courseAreaId?: string) =>
      items.value.findIndex(
        ({ id }) => id?.toUpperCase() === courseAreaId?.toUpperCase()
      ) !== -1
  )

  async function fetchAll() {
    // 'item.length' unreliable since using single
    // fetches (fetchOne/fetchBySlug) will add items to the array
    if (!flags.value.setAll) {
      const { fetch } = useFrontDoorApi()
      const data = await app.runWithContext(() =>
        fetch<CourseAreaData, CourseArea[] | undefined>(
          '/api/course-areas',
          '/api/ssc/e2e.coursearea/CourseAreaGetAll',
          (data: CourseAreaData) =>
            Array.isArray(data) ? data : data?.courseAreas
        )
      )

      if (!data || !Array.isArray(data)) {
        return
      }

      items.value = data
      flags.value.setAll = true
    }

    return items.value
  }

  async function fetchOne(id?: string) {
    if (!id) return

    // Sitecore datamodel changed, id === slug
    await app.runWithContext(() => fetchBySlug(id))
  }

  async function fetchBySlug(slug?: string) {
    if (!slug) return

    const courseArea = getBySlug.value(slug)

    if (!courseArea || fetchRequired(courseArea)) {
      const { fetch } = useFrontDoorApi()
      const data = await app.runWithContext(() =>
        fetch<CourseArea>(
          `/api/course-areas/${slug}`,
          `/api/ssc/e2e.coursearea/CourseArea/slug-${slug}`
        )
      )

      if (!data) {
        return
      }

      const index = items.value.findIndex(
        ({ id }) => id?.toUpperCase() === data?.id?.toUpperCase()
      )

      if (index >= 0) {
        items.value[index] = { ...items.value[index], ...data }
      } else {
        items.value.push(data)
      }

      return data
    }

    return courseArea
  }

  async function fetchByProductId(id?: string) {
    if (!id) return

    if (
      Object.keys(courseAreasMap.value.items).length === 0 ||
      Object.keys(courseAreasMap.value.override).length === 0
    ) {
      const mainStore = useMainStore()
      const url = `${mainStore.origin}/api/course-areas/mapping`
      courseAreasMap.value = await app.runWithContext(() =>
        $fetch<CourseAreaMap>(url, {
          method: 'GET'
        })
      )
    }

    let courseAreaId: string | undefined

    // length check is a rudimentary check to skip course area ids
    if (id && id.length > 4) {
      const prefix = id.substring(0, 3)

      // matching course/offering prefix
      courseAreaId = courseAreasMap.value.items[prefix]

      // regex match override (longest match takes priority)
      Object.keys(courseAreasMap.value.override)
        .filter((key) => id.match(new RegExp(`^${key}`, 'i')))
        .sort((a, b) => b.length - a.length)
        .every((key) => {
          courseAreaId = courseAreasMap.value.override[key]
          return false
        })
    }

    if (courseAreaId) {
      await app.runWithContext(() => fetchOne(courseAreaId))
      return getOne.value(courseAreaId)
    }
  }

  const fetchRequired = (courseArea?: CourseArea) =>
    !!courseArea && !Object.hasOwn(courseArea, 'subjectGroups')

  return {
    flags,
    items,
    courseAreasMap,
    getAll,
    getOne,
    getBySlug,
    getByIds,
    courseAreaExists,
    fetchAll,
    fetchOne,
    fetchBySlug
  }
})
